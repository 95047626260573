@import '../../../../scss/theme-bootstrap.scss';

.comparison-field {
  margin-bottom: 10px;
  padding: 0 15px;
  &--label {
    @include body-text--small-bold;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
  &--value {
    @include legal-text;
    color: $color-text-grey;
    margin-bottom: 6px;
    @include breakpoint($bp--large-down) {
      font-size: 12px;
    }
  }
}
